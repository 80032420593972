import React from "react";
import Layout from "./src/components/layout";

const transitionDelay = 700;

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition
}) => {
  if (location.action === "PUSH") {
    window.setTimeout(() => window.scrollTo(0, 0), transitionDelay);
  } else {
    const savedPosition = getSavedScrollPosition(location);
    window.setTimeout(
      () => window.scrollTo(...(savedPosition || [0, 0])),
      transitionDelay
    );
  }
  return false;
};

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `Updated version available` +
    `Reload to display the latest version.`
  )

  if (answer === true) {
    window.location.reload()
  }
}