import React from "react"
import { changeLocale, injectIntl, IntlContextConsumer } from "gatsby-plugin-intl-v3"
import { Link } from "gatsby-plugin-intl-v3";
const languageName = {
  en: "EN",
  tr: "TR",
};
const Header = ({intl}) => (
  <header>
    <div className="md:fixed top-0 left-0 z-50 p-4 py-6 md:p-12">
      <div className="flex flex-wrap md:block">
        <Link className="uppercase font-sans font-bold tracking-widest mb-0 pb-0 block text-base" to="/">
          HÜSEYİN SERMET
          </Link>

        <nav className="ml-auto md:ml-0 text-right md:text-left md:mt-4">
          <ul>
            <li>
              <Link
                activeClassName="is--active"
                className="nav-item font-sans uppercase inline-block mb-1 text-sm"
                to="/about">
                {intl.formatMessage({ id: "about" })}
              </Link>
            </li>
            <li>
              <Link
                activeClassName="is--active"
                className="nav-item font-sans uppercase inline-block mb-1 text-sm"
                partiallyActive={true}
                to="/discography">
                {intl.formatMessage({ id: "discog" })}
              </Link>
            </li>
            <li>
              <Link
                activeClassName="is--active"
                className="nav-item font-sans uppercase inline-block mb-1 text-sm"
                partiallyActive={true}
                to="/compositions">
                {intl.formatMessage({ id: "composition" })}
              </Link>
            </li>
            <li>
              <Link
                activeClassName="is--active"
                className="nav-item font-sans uppercase inline-block mb-1 text-sm"
                partiallyActive={true}
                to="/press">
                {intl.formatMessage({ id: "press" })}
              </Link>
            </li>
            <li>
              <Link
                activeClassName="is--active"
                className="nav-item font-sans uppercase inline-block mb-1 text-sm"
                partiallyActive={true}
                to="/gallery">
                {intl.formatMessage({ id: "gallery" })}
              </Link>
            </li>
            <li>
              <Link
                activeClassName="is--active"
                className="nav-item font-sans uppercase inline-block mb-1 text-sm"
                partiallyActive={true}
                to="/contact">
                {intl.formatMessage({ id: "contact" })}
              </Link>
            </li>
            <li className="mt-3 mb-3 nav-item font-sans uppercase text-sm">
              <IntlContextConsumer>
                {({ languages }) =>
                  languages.map((language) => (
                      <button
                        key={language}
                        className="mr-1 text-sm outline-none border-none focus:outline-none"
                        onClick={() => changeLocale(language)}
                      >
                        {languageName[language]}
                      </button>
                  ))
                }
              </IntlContextConsumer>

            </li>
            <li>
              <a
                activeClassName="is--active"
                className="nav-item font-sans uppercase inline-block mb-1 text-sm"
                partiallyActive={true}
                href="https://www.instagram.com/sermetofficial/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Instagram
              </a>
            </li>
            <li>
              <a
                activeClassName="is--active"
                className="nav-item font-sans uppercase inline-block mb-1 text-sm"
                partiallyActive={true}
                href="https://www.facebook.com/huseyinsermetofficial/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Facebook
              </a>
            </li>
            <li>
              <a
                activeClassName="is--active"
                className="nav-item font-sans uppercase inline-block mb-1 text-sm"
                partiallyActive={true}
                href="https://open.spotify.com/artist/5KvXtACyBFALB9l4pE5gzj?si=tHq9GaSpSYiCaUZvQzi4tg"
                target="_blank"
                rel="noopener noreferrer"
              >
                Spotify
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>

    <div className="hidden md:block fixed top-0 right-0 z-50 p-6 md:p-12">
      <span className="text-sm uppercase font-sans meta-date leading-none">
      {intl.formatMessage({ id: "line" })}
        <span className="block text-right">{intl.formatMessage({ id: "linetwo" })}</span>
      </span>
    </div>
  </header>
)

export default injectIntl(Header)