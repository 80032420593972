import React from "react"
import PropTypes from "prop-types"
import { AnimatePresence } from 'framer-motion'
import { IntlProvider, IntlContextProvider } from "gatsby-plugin-intl-v3";
import Header from "./header"
import "../styles/main.css"

const Layout = (props) => {
  const intl = props.pageContext.intl;

  const children = props.children;
  return (
    <IntlProvider
      locale={intl.language}
      defaultLocale={intl.defaultLanguage}
      messages={intl.messages}
    >
      <IntlContextProvider value={intl}>

      <Header />
      <AnimatePresence exitBeforeEnter>
        {children}
      </AnimatePresence>

      </IntlContextProvider>
    </IntlProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
