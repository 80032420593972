"use strict"

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault")

exports.__esModule = true
exports.wrapPageElement = void 0

var _wrapPage = _interopRequireDefault(require("./wrap-page"))

var wrapPageElement = _wrapPage.default
exports.wrapPageElement = wrapPageElement
